import styles from "./LoadingSpinnerPage.module.scss";

import { projectName } from "../../../data/project.js";

export default function LoadingSpinnerPage() {
	return (
		<div className={styles.container}>
			<span className={styles.logo_text}>{projectName}</span>
		</div>
	);
}